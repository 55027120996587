import { Grid } from "@mui/material"
import Ebooks from "../../components/ebook";
import useEbooks from "../../context";

const PageApp = () => {

    const { search } = useEbooks();

    return(
        <Grid>
            <Ebooks search={search}/>
        </Grid>
    )
}
export default PageApp;