import { PaletteOptions } from "@mui/material";

export default function themePalette(): PaletteOptions {
    return {
        mode: "light",
        common: {
            black: '#000000'
        },
        primary: {
            light: '#FFA347',
            main: '#EC6615',
            dark: '#A63E05'
        },
        error: {
            light: '#FFC3B1',
            main: '#FF5733',
            dark: '#DB3B0B'
        },
        warning: {
            light: '#FFFACD',
            main: '#FFD700',
            dark: '#BDB76B'
        },
        success: {
            light: '#C3E6CB',
            main: '#008000',
            dark: '#004D00'
        },
        grey: {
            50: '#F5F5F5',
            100: '#F0F0F0',
            500: '#CCCCCC',
            600: '#999999',
            700: '#666666',
            900: '#333333'
        },
        text: {
            primary: '#333333',
            secondary: '#666666'
        }
    };
}
