import { Grid } from "@mui/material"
import { Outlet } from "react-router-dom";
import useEbooks from "../context";
import TopAppBar from "../components/topBar";


const MainLayout = () => {

    const { search, setSearch} = useEbooks();

    return(
        <Grid>
            <TopAppBar search={search} setSearch={setSearch}/>
            <Outlet />
        </Grid>
    )
}
export default MainLayout;