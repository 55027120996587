import { useRoutes } from "react-router-dom"
import PageApp from "../pages/app";
import DetailsPage from "../pages/app/details";
import MainLayout from "../layout/main";
import CheckoutPage from "../pages/checkout";
import CartPage from "../pages/cart";

const Routers = () => {
    return useRoutes([
        {
            element: <MainLayout />,
            path: "",
            children: [
                {
                    element: <PageApp />,
                    path: "/",
                },
                {
                    element: <DetailsPage />,
                    path: "/:title",
                },
                {
                    element: <CartPage />,
                    path: "/cart",
                },
                {
                    element: <CheckoutPage />,
                    path: "/checkout",
                }
            ]
        },
    ])
}

export default Routers;