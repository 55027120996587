import { Button, Container, Divider, Grid, Table, TableBody, TableCell, TableHead, TableRow, Typography, useTheme } from "@mui/material"
import useEbooks from "../../context";
import { moneyMask } from "../../utils/mask";
import { useNavigate } from "react-router-dom";

const CartPage = () => {

    const navigate = useNavigate();

    const theme = useTheme();

    const { cart, totalValue, setCart } = useEbooks();

    return (
        <Container>
            <Table sx={{ mt: 5 }}>
                <TableHead>
                    <TableCell>Produto</TableCell>
                    <TableCell>Preço</TableCell>
                    <TableCell align="center">Quantidade</TableCell>
                    <TableCell align="center">Subtotal</TableCell>
                </TableHead>
                <TableBody>
                    {
                        cart?.map((_item, index) => (
                            <TableRow key={index}>
                                <TableCell>{_item.title}</TableCell>
                                <TableCell>{moneyMask(String(_item.price.toFixed(2)) ?? "0")}</TableCell>
                                <TableCell align="center">{_item?.quantidade ?? 1}</TableCell>
                                <TableCell align="center">{moneyMask(String(((_item?.quantidade ?? 1) * _item.price).toFixed(2)) ?? "0")}</TableCell>
                            </TableRow>
                        ))
                    }
                </TableBody>
            </Table>
            <Grid 
            container 
            flexDirection={'column'}
            gap={2}
            alignItems={'flex-end'} 
            mt={5} 
            
            >
                <Typography variant="h2">
                    Total no carrinho
                </Typography>
                <Divider />
                <Typography variant="h3" color={theme.palette.grey[700]}>
                    Subtotal {moneyMask(String(totalValue?.toFixed(2) ?? "0"))}
                </Typography>
                <Typography variant="h3" color={theme.palette.grey[700]}>
                    Total {moneyMask(String(totalValue?.toFixed(2) ?? "0"))}
                </Typography>
                <Button onClick={() => navigate('/checkout')} variant="contained" sx={{ minWidth: 200 }}>
                    Finalizar comprar
                </Button>
            </Grid>
        </Container>
    )
}

export default CartPage;