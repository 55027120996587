import { Box, Button, Container, Grid, Typography, useTheme } from "@mui/material"
import { useEffect, useState } from "react";
import { useNavigation, useLocation } from "react-router-dom";
import { BookProps } from "../../components/ebook";
import { moneyMask } from "../../utils/mask";
import useEbooks from "../../context";

const DetailsPage = () => {

    const { state } = useLocation();

    const { cart, setCart } = useEbooks();

    const theme = useTheme();

    const [currentEbook, setCurrentEbook] = useState<BookProps | null>(null)

    const ExistInCart = cart.find(_cart => _cart.id === currentEbook?.id)

    useEffect(() => {
        if (state) {
            setCurrentEbook(state as unknown as BookProps)
        }
    }, [state])

    const addOrRemove = () => {
        if(!currentEbook){
            return
        }
        if (!cart.find(_cart => _cart.id === currentEbook?.id)) {
            setCart([...cart, currentEbook])
        } else {
            setCart([...cart?.filter(_cart => _cart.id !== currentEbook.id)])
        }
    }

    if (!currentEbook) {
        return (
            <Grid></Grid>
        )
    }

    return (
        <Container>
            <Grid container gap={2} p={2} justifyContent={"center"}>
                <img src={currentEbook?.image} style={{ maxWidth: 450 }} width="100%" alt={currentEbook?.title} />
                <Box>
                    <Grid container flexDirection={"column"} gap={1}>
                        <Typography>{currentEbook.category}</Typography>
                        <Typography variant="h1" maxWidth={400}>{currentEbook.title}</Typography>
                        <Typography variant="h3" color={theme.palette.grey[700]}>{moneyMask(currentEbook.price.toFixed(2))}</Typography>
                        <Grid container gap={2}>
                            <Button variant="outlined" fullWidth onClick={addOrRemove}>{ExistInCart ? "Remover do carrinho" : "Adicionar ao"} carrinho</Button>
                            <Button variant="contained" fullWidth>Comprar</Button>
                        </Grid>
                    </Grid>
                </Box>
            </Grid>
        </Container>
    )
}

export default DetailsPage;