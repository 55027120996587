import React, {
    createContext,
    useContext,
    useEffect,
    useState
} from "react";
import { BookProps } from "../components/ebook";

interface ContextProps {
    cart: BookProps[],
    setCart(a: BookProps[]): void;
    totalValue: number;
    search: string,
    setSearch(a: string): void;
}

export const EbooksManager = createContext({} as ContextProps);

const useEbooks = () => useContext(EbooksManager);

export const EbooksProvider = ({ children }: { children: React.ReactNode }) => {

    const [cart, setCart] = useState<BookProps[]>([])
    const [totalValue, setTotalValue] = useState(0)
    const [search, setSearch] = useState('')

    useEffect(() => {
        const total = cart?.reduce((accumulator, item) => accumulator + (item.price * (item.quantidade ?? 1)), 0);
        setTotalValue(total)
    },[cart])

    return (
        <EbooksManager.Provider value={{
            cart, setCart, totalValue, search, setSearch
        }}>
            {children}
        </EbooksManager.Provider>
    )
}

export default useEbooks;