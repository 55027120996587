import { MdRemoveCircle, MdSearch, MdShoppingCart } from "react-icons/md"

import * as React from 'react';
import { styled, alpha } from '@mui/material/styles';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import InputBase from '@mui/material/InputBase';
import Badge from '@mui/material/Badge';
import { Button, Divider, Grid, Popover } from "@mui/material";
import useEbooks from "../context";
import { moneyMask } from "../utils/mask";
import { BookProps } from "./ebook";
import { useNavigate } from "react-router-dom";

const Search = styled('div')(({ theme }) => ({
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    '&:hover': {
        backgroundColor: alpha(theme.palette.common.white, 0.25),
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
        marginLeft: theme.spacing(3),
        width: 'auto',
    },
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
    color: 'inherit',
    '& .MuiInputBase-input': {
        padding: theme.spacing(1, 1, 1, 0),
        paddingLeft: `calc(1em + ${theme.spacing(4)})`,
        transition: theme.transitions.create('width'),
        width: '100%',
        [theme.breakpoints.up('md')]: {
            width: '20ch',
        },
    },
}));

export default function TopAppBar({ search, setSearch }: { search: string, setSearch(a: string): void; }) {

    const navigate = useNavigate();

    const { cart, totalValue, setCart } = useEbooks();

    const [anchorElCart, setAnchorElCart] = React.useState<HTMLElement | null>(null);

    const handleClose = () => {
        setAnchorElCart(null);
    };

    const RemoveCartItem = (a: BookProps) => {
        setCart([...cart?.filter(_cart => _cart.id !== a.id)])
    }

    return (
        <Box sx={{ flexGrow: 1 }}>
            <AppBar position="static">
                <Toolbar>
                    <Typography
                        variant="h3"
                        noWrap
                        color="white"
                        component="div"
                        sx={{ display: { xs: 'none', sm: 'block' } }}
                    >
                        Ebook favorito
                    </Typography>
                    <Search>
                        <SearchIconWrapper>
                            <MdSearch />
                        </SearchIconWrapper>
                        <StyledInputBase
                            value={search}
                            onChange={({ target }) => setSearch(target.value)}
                            placeholder="Pesquisar…"
                            inputProps={{ 'aria-label': 'search' }}
                        />
                    </Search>
                    <Box sx={{ flexGrow: 1 }} />
                    <Box>
                        <Grid container alignItems="center" gap={2} px={2} aria-owns={!!anchorElCart ? 'mouse-over-popover' : undefined}
                            onMouseEnter={event => setAnchorElCart(event.currentTarget)}>
                            <Typography
                                variant="h4"
                                noWrap
                                color="white"
                                component="div"
                                sx={{ display: { xs: 'none', sm: 'block' } }}
                            >
                                {moneyMask(String(totalValue.toFixed(2) ?? 0))}
                            </Typography>
                            <IconButton

                            // onMouseLeave={handleClose}
                            >
                                <Badge badgeContent={cart?.length ?? 0} color="info">
                                    <MdShoppingCart />
                                </Badge>
                            </IconButton>
                            <Popover
                                id="mouse-over-popover"
                                onClose={handleClose}
                                onMouseLeave={handleClose}
                                open={!!anchorElCart}
                                anchorEl={anchorElCart}
                                anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'left',
                                }}
                            >
                                <Grid p={2} container gap={2} maxWidth={350}>
                                    <Grid container gap={2} maxHeight={400} overflow={'auto'}>
                                        {
                                            cart?.map((_ebook) => {
                                                const amount = _ebook?.quantidade ?? 1
                                                return (
                                                    <Grid item container gap={2}>
                                                        <img
                                                            src={_ebook.image}
                                                            width={50}
                                                            alt="ebook" />
                                                        <Grid width={"calc(100% - 82px)"}>
                                                            <Typography
                                                                component="div"
                                                                sx={{ maxWidth: 150 }}
                                                            >
                                                                {_ebook.title}
                                                            </Typography>
                                                            <Grid container justifyContent={'space-between'}>
                                                                <Typography
                                                                    color="GrayText"
                                                                    mt={2}
                                                                    component="div"
                                                                    sx={{ maxWidth: 150 }}
                                                                >
                                                                    {amount}x {moneyMask(String(_ebook.price.toFixed(2) ?? 0))}
                                                                </Typography>
                                                                <IconButton color="error" onClick={() => RemoveCartItem(_ebook)}>
                                                                    <MdRemoveCircle />
                                                                </IconButton>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                )
                                            })
                                        }
                                    </Grid>

                                    <Divider />
                                    <Grid container justifyContent={'space-between'} alignItems="center">
                                        <Typography
                                            color="GrayText"
                                        >
                                            Subtotal
                                        </Typography>
                                        <Typography
                                            color="GrayText"
                                        >
                                            {moneyMask(String(totalValue.toFixed(2) ?? 0))}
                                        </Typography>
                                    </Grid>
                                    <Divider />
                                    <Button variant="outlined" fullWidth onClick={() =>{
                                        navigate('/cart'); 
                                        handleClose();
                                        }}>
                                        Ver carrinho
                                    </Button>
                                    <Button variant="contained" fullWidth onClick={() =>{
                                        navigate('/checkout'); 
                                        handleClose();
                                        }}>
                                        Finalizar compra
                                    </Button>
                                </Grid>
                            </Popover>
                        </Grid>
                    </Box>
                </Toolbar>
            </AppBar>
        </Box>
    );
}