import { Box, Button, Container, Grid, IconButton, Pagination, TextField, Tooltip, Typography, useTheme } from "@mui/material"

import EbooksJSON from "../assets/ebooks.json"
import { useEffect, useState } from "react"
import { MdAddShoppingCart, MdRemoveCircle } from "react-icons/md";
import axios from "axios";
import { moneyMask } from "../utils/mask";
import useEbooks from "../context";
import { useNavigate } from "react-router-dom";

export interface BookProps {
    category: string
    description: string
    id: number
    image: string
    name: string
    price: number
    priceWrong: number
    title: string;
    quantidade?: number
}


const Ebooks = ({ search }: { search: string }) => {

    const { setCart, cart } = useEbooks();

    const [ebooks, setEbooks] = useState<BookProps[]>(EbooksJSON.books);
    const [AllEbooks, setAllEbooks] = useState<BookProps[]>(EbooksJSON.books);
    const [page, setPage] = useState(1);

    useEffect(() => {
        (async () => {

            try {
                const fecthebooks = await axios.get('https://ebooks-bpb.s3.amazonaws.com/books.json')
                if (fecthebooks?.data?.books) {
                    setEbooks(fecthebooks.data.books)
                    setAllEbooks(fecthebooks.data.books)
                }
            }
            catch (err) {

            }

        })()
    }, [])

    useEffect(() => {
        let searchEbooks: BookProps[] = []
        AllEbooks.map((_ebook) => {
            if (_ebook?.title.includes(search) || moneyMask(String(_ebook?.price)).includes(search)) {
                searchEbooks.push(_ebook)
            }
        })
        setEbooks(searchEbooks)
    }, [search])

    const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
        setPage(value);
    };

    const AddOrRemoveCartItem = (a: BookProps) => {
        if (!cart.find(_cart => _cart.id === a.id)) {
            setCart([...cart, a])
        } else {
            setCart([...cart?.filter(_cart => _cart.id !== a.id)])
        }
    }

    return (
        <Container>
            <Grid p={2} container gap={2} justifyContent="center">
                {ebooks?.slice((page * 8) - 8, (page * 8))?.map((_ebook) => {
                    const ExistInCart = cart.find(_cart => _cart.id === _ebook.id)
                    return (
                        <EbookItem ExistInCart={ExistInCart} AddOrRemoveCartItem={AddOrRemoveCartItem} _ebook={_ebook} />
                    )
                })}
            </Grid>
            <Grid p={2} >
                <Pagination
                    color="primary"
                    onChange={handleChange}
                    count={(ebooks?.length / 8) ?? 0} />
            </Grid>
        </Container>
    )
}

const EbookItem = ({ ExistInCart, AddOrRemoveCartItem, _ebook }: {
    ExistInCart: BookProps | undefined,
    _ebook: BookProps,
    AddOrRemoveCartItem: (a: BookProps) => void
}) => {

    const navigte = useNavigate();

    const { setCart, cart } = useEbooks();

    const theme = useTheme();

    const [qtn, setQtn] = useState(1);

    const HandleChange = (a: number) => {
        if (a >= 1) {
            setQtn(a)
        }
    }

    useEffect(() => {
        if (ExistInCart) {
            setCart([
                ...cart.filter((item) => item.id !== ExistInCart.id),
                {
                    ...ExistInCart,
                    quantidade: qtn
                }
            ])
        }
    }, [qtn])

    return (
        <Box
            sx={{
                bgcolor: theme.palette.grey[100],
                maxWidth: 200,
                border: '1px solid',
                borderColor: 'transparent', p: 2,
                borderRadius: 2,
                transition: '.5s',
                cursor: 'pointer',
                ':hover': {
                    borderColor: theme.palette.primary.main
                }
            }}>
            <Grid justifyContent={'space-between'} container gap={1} flexDirection={'column'} height="100%">
                <img
                    onClick={() => navigte(_ebook.name, {
                        state: _ebook
                    })}
                    height={200}
                    width={200}
                    src={_ebook.image} />
                <Box>
                    <Grid onClick={() => navigte(_ebook.name, {
                        state: _ebook
                    })}>

                        <Typography>{_ebook.title}</Typography>
                        <Grid container my={2} gap={1}>
                            {
                                _ebook?.priceWrong > _ebook.price && <Grid component={Typography} sx={{
                                    position: 'relative',
                                    ':after': {
                                        content: '""',
                                        position: 'absolute',
                                        top: '50%',
                                        left: '50%',
                                        transform: 'translate(-50%, -50%)',
                                        height: .02,
                                        bgcolor: '#000000',
                                        width: '100%'
                                    }
                                }}>
                                    {moneyMask(String(_ebook.priceWrong.toFixed(2)))}
                                </Grid>}
                            <Typography>
                                {moneyMask(String(_ebook.price))}
                            </Typography>
                        </Grid>

                    </Grid>
                    {ExistInCart && <TextField
                        value={qtn}
                        type="number"
                        onChange={(event) => HandleChange(Number(event.target.value))}
                        label="Quantidade" sx={{ my: 1 }} />}
                    <Grid container justifyContent={'space-between'}>
                        <Button 
                        sx={{ textTransform: 'none', minWidth: 155 }}
                         onClick={() => AddOrRemoveCartItem(_ebook)}
                        variant={ExistInCart ? "outlined" : "contained"} >
                          {ExistInCart ? <MdRemoveCircle size={25}/> : <MdAddShoppingCart size={25}/>}  
                          {ExistInCart ? "Remover do carrinho" : "Adicionar ao carrinho"}
                        </Button>
                    </Grid>
                </Box>
            </Grid>
        </Box>
    )
}

export default Ebooks;