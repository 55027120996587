import { ThemeProvider } from '@emotion/react';
import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import theme from './theme';
import Routers from './routes';
import { EbooksProvider } from './context';

function App() {
  return (
    <BrowserRouter>
      <EbooksProvider>
        <ThemeProvider theme={theme()}>
          <Routers />
        </ThemeProvider>
      </EbooksProvider>
    </BrowserRouter>
  );
}

export default App;
