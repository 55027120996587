import { Button, Container, Divider, Grid, Table, TableBody, TableCell, TableHead, TableRow, TextField, Typography, useTheme } from "@mui/material"
import useEbooks from "../../context";
import { moneyMask } from "../../utils/mask";

import PIX from "../../assets/pix.png"

const CheckoutPage = () => {

    const theme = useTheme();

    const { cart, totalValue, setCart } = useEbooks();

    return (
        <Container>
            <Grid container mt={5}>
                <Grid xs={12} md={6} lg={6} container gap={2} p={2} alignContent={'flex-start'}>
                    <Typography variant="h3" width="100%">
                        Detalhes de faturamento
                    </Typography>
                    <Grid xs={12} container gap={2}>
                        <Grid xs={12} md={5.8} lg={5.8}>
                            <TextField
                                label="Nome"
                                fullWidth
                            />
                        </Grid>
                        <Grid xs={12} md={5.8} lg={5.8}>
                            <TextField
                                label="Sbrenome"
                                fullWidth
                            />
                        </Grid>
                    </Grid>
                    <TextField
                        fullWidth
                        label="Telefone"
                    />
                    <TextField
                        fullWidth
                        label="E-mail"
                    />
                </Grid>
                <Grid xs={12} md={6} lg={6} container gap={2} p={2}>
                    <Typography variant="h3" width="100%">
                        Seu pedido
                    </Typography>
                    <Grid container>
                        <Table>
                            <TableHead>
                                <TableCell>
                                    Produto
                                </TableCell>
                                <TableCell>
                                    Subtotal
                                </TableCell>
                            </TableHead>
                            <TableBody>
                                {
                                    cart?.map((_item, index) => (
                                        <TableRow key={index}>
                                            <TableCell>{_item.name}</TableCell>
                                            <TableCell align="center">{moneyMask(String(((_item?.quantidade ?? 1) * _item.price).toFixed(2)) ?? "0")}</TableCell>
                                        </TableRow>
                                    ))
                                }
                                <TableRow>
                                    <TableCell>Subtotal</TableCell>
                                    <TableCell align="right">{moneyMask(String(totalValue?.toFixed(2)) ?? "0")}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>Total</TableCell>
                                    <TableCell align="right">{moneyMask(String(totalValue?.toFixed(2)) ?? "0")}</TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </Grid>
                    <img src={PIX} width="100%" style={{ maxWidth: 300 }} />
                    <Typography>
                        Pague de forma segura e instantânea
                        Ao confirmar a compra, nós vamos te mostrar o código para fazer o pagamento.
                    </Typography>
                    <Button
                        variant="contained"
                        sx={{
                            p: 1.2,
                            mt: 2
                        }}
                        fullWidth
                    >
                        Finalizar pedido
                    </Button>
                </Grid>
            </Grid>
        </Container>
    )
}

export default CheckoutPage